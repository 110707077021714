import React from 'react'
import { Link } from 'react-router-dom';

const AdminNav = () =>{
    return (
        <div className="w-full h-auto flex flex-row justify-between border-b border-gray-100 shadow-md px-12 py-2">
            <div className="w-12 h-12 flex flex-row items-center justify-center">
                  <img className="h-10 w-10" src={require('../../images/logo/logo.png').default}/>
            </div>
            <ul className="w-auto h-12 px-2 flex flex-row items-center space-x-2">
                {/** produit */}
                <li className="w-auto h-10 px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-col group">
                    <span className="flex flex-row">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                    </svg>
                        articles</span>
                    <ul className="absolute top-12 bg-white z-10 hidden group-hover:flex transition duration-1500 ease-in-out flex-col w-auto h-auto px-4 py-4 border rounded-md mt-2 divide-y-2 divide-gray-100">
                        
                        
                         <Link to={"/items"} className="h-8 items-center  flex flex-row">
                             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                             </svg>
                            <span className="text-sm capitalize font-bold text-gray-700 ml-2 roboto">list des produit</span>
                        </Link>



                        <Link to={"/additem"} className="h-8 items-center  flex flex-row">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
                                </svg>
                            <span className="text-sm capitalize font-bold text-gray-700 ml-2">nouveau article</span>
                        </Link>

                        
                        <li className="h-8 items-center  flex">statistique</li>
                        <li className="h-8 items-center  flex">les plus vendus</li>
                    </ul>
                </li>
                
                {/** brand */}
                <li className="w-auto h-auto px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-row group">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                    <span>Marque</span>
                </li>

                {/** categorie */}
                <li className="w-auto h-auto px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-row group">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    <span>categories</span>
                </li>

                <li className="w-auto h-auto px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer flex flex-row group">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-400 group-hover:text-gray-700 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>clients</span>
                </li>
                <li className="w-auto h-auto px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer">ventes</li>
                <li className="w-auto h-auto px-2 py-2 text-sm font-bold capitalize hover:bg-yellow-400 cursor-pointer">commandes</li>
                
            </ul>
        </div>
    )
}

export default AdminNav
