import React from 'react'
import Navl1 from './Navl1';
import Navl2 from './Navl2';
import Navl3 from './Navl3';

const Nav = () => {
    return (
        <div className="w-full h-auto fixed shadow-md bg-gray-50 top-0 z-30">
            <Navl2 />
            <Navl3 />
        </div>
    )
}

export default Nav
