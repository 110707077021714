import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';


const ListOfItems = () =>{
    const [products, setProducts] = useState([])
    const [p, setP] = useState([])
    const [search, setSearch] = useState()

    //const br = useRef(null)
    const urlAPI = "https://apis.gomispro.com/admin/items"

      useEffect(() => {
            axios.get(urlAPI).then(res => { 
            setProducts(res.data)
            //setLoad(true)
            
            }).catch(err => {
            console.log(err)
            })
        }, [])
        
        const refreshPage = ()=>{
          window.location.reload();
       }

        const deleteItem = (id) => {
          const urlAPIDELETE = `https://apis.gomispro.com/product/delete.php?id=${id}`
          axios.get(urlAPIDELETE).then(res => { 
              refreshPage()
            }).catch(err => {
            console.log(err)
            })
        } 
    return (
        <div className="w-full h-screen flex flex-col px-4">
            
            
            {/*
            <DataGrid
                rows={products}
                columns={columns}
                pageSize={13}
                checkboxSelection
              />
            */}
            <table className="table-fixed">
                  <thead className="bg-yellow-400">
                    <tr className="text-sm text-left h-12 border-b border-gray-700 bg-gray-200 px-2" >
                      <th>ID</th>
                      <th>marque</th>
                      <th>article</th>
                      <th>ref</th>
                      <th>prix</th>
                      <th>volume</th>
                      <th>etat</th>
                      <th>stock</th>
                      <th>actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      products.length && products.map((product, index)=>
                          <tr className="h-12 border-b border-gray-300 py-2 hover:bg-gray-200" >
                            <td>{product.id}</td>
                            <td className="flex flex-row ">
                              <div className="w-8 h-8 mr-2">
                                 
                              </div>
                              {product.brand}
                            </td>
                            <td>{product.produit}</td>
                            <td>{product.ref}</td>
                            <td>{product.price}</td>
                            <td>{product.volume} - {product.unite}</td>
                            <td>{product.instock > 0 ? "Disponible" : "Repture"}</td>
                            <td>{product.instock}</td>
                            <td>
                              <div className="flex flex-row">
                                  {/* edit */}
                                    <Link to={`/updateItem/${product.id}`} className="group"> 
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 group-hover:text-green-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                    </Link>
                                    {/* delate */}
                                    <span className="group" onClick={()=>deleteItem(`${product.id}`)}> 
                                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 group-hover:text-red-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                      </svg>
                                    </span>
                              </div>
                              
                            </td>
                          </tr>
                      )
                    }
                  </tbody>
            </table>
            
        </div>
    )
}

export default ListOfItems
