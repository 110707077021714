import { render } from '@testing-library/react';
import React from 'react'
import ToPrint from './ToPrint';
import jsPDF from 'jspdf';
import * as _html2canvas from "html2canvas";
import ComponentToPrint from './ComponentToPrint';
//const html2canvas: any = _html2canvas;
class MyDocument extends React.Component {
  constructor(props){
    super(props);
    this.state = {}
  }



generatePdf=()=>{
 
    var doc = new jsPDF({
        orientation:'portrait',
        unit:'pt',
        format:'a2'
    }); 
    
    
    doc.html(document.querySelector("#content"), {
      callback: function (doc) {
        doc.save("freshcar99.pdf");
      }
    });
    
    alert("FROM TOPRINT")
}

render(){
  return(
    <div className="pt-20">
      <ComponentToPrint />
      <div className="w-full h-auto bg-blue-400 hidden" >
        <ToPrint />
      </div>
      <div className="w-full h-auto flex flex-row justify-center items-center ">
        <button 
        onClick={this.generatePdf}
        className="w-auto h-auto bg-blue-400 text-white px-4 py-2 ">Imprimer</button>
      </div>
    </div>
  )
}

}

export default MyDocument
