import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { decrement } from '../redux/cartCounter'
import { deletamount, addtoamount } from '../redux/totalInCart'
function ItemsInCart(){
    const cartItems = JSON.parse(localStorage.getItem("cartitems"))
    const [items, setItems] = useState([]);
    const [cartnumber, setcartnumber] = useState([]);
    const dispatch = useDispatch();

    const count = useSelector((state)=> state.cartCounter.count)

    useEffect(() => {
        setItems(cartItems)
    }, [])
    

    const disp = () => {
            dispatch(decrement());
    }

    const alterAmount = (q,p) => {
        let amount = q*p 
        dispatch(deletamount(amount));
    }

    const subAmount = (p) => { 
        dispatch(deletamount(p));
    }

    const incAmount = (p) => { 
        dispatch(addtoamount(p));
    }

    const incThisItem = (i) => {
        let cartItems = JSON.parse(localStorage.getItem("cartitems"))
        let totalInCart = localStorage.getItem("totalincart")
        let thisItem  = cartItems[i]
        thisItem.itemqtt = parseInt(thisItem.itemqtt) + 1
        incAmount(parseFloat(thisItem.itemprice))
        totalInCart = parseFloat(totalInCart, 0) + parseFloat(thisItem.itemprice, 0)
        localStorage.setItem("cartitems", JSON.stringify(cartItems))

        localStorage.setItem("totalincart", JSON.stringify(totalInCart))
        setItems(cartItems)
    }

    const decThisItem = (i) =>{
        let cartItems = JSON.parse(localStorage.getItem("cartitems"))
        let totalInCart = JSON.parse(localStorage.getItem("totalincart"))
        let thisItem  = cartItems[i]
         
        if(thisItem.itemqtt == 1){
                thisItem.itemqtt = 1
                 
        }else{
            thisItem.itemqtt = parseInt(thisItem.itemqtt) - 1
            subAmount(parseFloat(thisItem.itemprice).toFixed(2))
            totalInCart = totalInCart - parseFloat(thisItem.itemprice).toFixed(2)
        }
        localStorage.setItem("cartitems", JSON.stringify(cartItems))
        localStorage.setItem("totalincart", JSON.stringify(totalInCart))
        setItems(cartItems)
    }

    const deleteItem = (v) => {
        let cartItems = JSON.parse(localStorage.getItem("cartitems"))
        let cartnumber = JSON.parse(localStorage.getItem("cartnumber"))
        let totalincart = JSON.parse(localStorage.getItem("totalincart"))
        
        //delete cartItems[v]
        disp()
        alterAmount(cartItems[v].itemqtt,cartItems[v].itemprice);
        localStorage.setItem("totalincart", (JSON.stringify(parseFloat(totalincart)) - (cartItems[v].itemqtt * parseFloat(cartItems[v].itemprice) )));
        delete cartItems[v] 
        localStorage.setItem("cartitems", JSON.stringify(cartItems));
        localStorage.setItem("cartnumber", JSON.stringify(parseInt(cartnumber) - 1));
        //setcartnumber(cartnumber)
        
        setItems(cartItems)

    }
    return (
        <div className="w-full h-auto px-1">
            {
                      Object.keys(items).map((key, index)=>(
                       <div className="flex flex-row mb-2  bg-gray-200 px-2 py-2 rounded-md">
                           <div className="w-6 h-6 flex flex-row justify-center items-center rounded-full  bg-yellow-500 hover:bg-red-500">
                                <button className="w-4 h-4" onClick={
                                        ()=>deleteItem(items[key].itemid)
                                    }>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path className="bg-red-500"  strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                           </div>
                           <div className="w-24 h-24">
                                <img className="w-24 h-24"  src={require(`../images/10.png`).default} id="img"/>
                           </div>

                           <div className="flex xs:flex-col lg:flex-row lg:flex-grow ">
                                 <div className="xs:text-xs lg:w-1/5 pl-4">{items[key].itembrand}</div>
                                 <div className="xs:text-xs lg:w-1/5 pl-4">{items[key].itemname}</div>
                                 <div className="xs:text-xs lg:w-1/5 pl-4">{items[key].itemprice}</div> 
                                 <div className="py-1 mt-1 mb-1 flex flex-row items-center">
                                            <button className="w-10 h-10 px-2 py-2" onClick={
                                                () => decThisItem(items[key].itemid)
                                            }>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6" />
                                                </svg>
                                            </button>
                                            <span type="text" className="px-6 py-2 mx-2 border border-gray-400 rounded-lg">
                                                {items[key].itemqtt}
                                            </span>
                                            
                                            {/**   inc */}
                                            <button className="w-10 h-10 px-2 py-2" onClick={
                                                ()=>incThisItem(items[key].itemid)
                                            }>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                            </button>
                                </div>   
                                <div className="text-md font-bold text-red-400 lg:w-1/5  text-center">
                                            {(items[key].itemprice * items[key].itemqtt).toFixed(2)}
                                </div>   
                           </div>

                                                    
                       </div>
                    ))
                }

        </div>
    )
}

export default ItemsInCart
