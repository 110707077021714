import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { increment } from '../redux/cartCounter'
import { addtoamount } from '../redux/totalInCart'
import quantity, { incrementQtt, decrementQtt } from '../redux/quantity'
import SameProduct from './SameProduct';
import MainItem from './MainItem';
import SameCategorie from './SameCategorie';




const Details = () =>{
     
    //const refreshPage = ()=>{
        //window.location.reload();
     //}
    
    const [p, setP] = useState([])
    
    const { id } = useParams();
 
    //setUrlId(id)
         const urlAPI = `https://apis.gomispro.com/product/product.php?idv=${id}`

        useEffect(() => {
            console.log("start axios fetch in details")
            
            axios.get(urlAPI).then(res => { 
            setP(res.data)
            
        }).catch(err => {
            console.log(err)
            })
        }, [])

    
    return (
        <div className="flex flex-col mt-20 ">     
            
       {
           
           p.length && p.map((item, index)=>
                <div>
                    <div className="w-full h-screen flex flex-row lg:pl-12 lg:pr-12">
                        {/* left side */}
                            <div className="lg:w-1/4 lg:h-full xs:hidden lg:block ">
                                {item.produit}
                            </div>
                        {/*  right side*/}
                        <MainItem productid={id} />                            
                    </div>   
                    
                    {/*<SameCategorie categorie={item.categorie}/>*/}
                    {/*<SameProduct brand={item.brand}/>*/}
                </div>
           )
       }
        
        
        
        
    
        </div>                            
        )
}

export default Details