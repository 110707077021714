import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useCookies, withCookies, Cookies } from 'react-cookie';
const  MainDetails = (props)  =>{
        
       
        //let ls_client = localStorage.getItem("cartitems")
        const [checked, setChecked] = useState(false)
        //const [stt, setStt] = useState(false)
    
    const handleChange = () => {
        setChecked(!checked);
        //setStt(!stt)
      };

  
    const [firstname, setFirstName] = useState()
    const [lastName, setName] = useState()
    const [rp, setRP] = useState()
    const [adr, setAdr] = useState()
    const [street, setStreet] = useState()
    const [zip, setZip] = useState()
    const [tel, setTel] = useState()
    const [mail, setMail] = useState()

    const [firstname_s, setFirstNameS] = useState()
    const [lastName_s, setNameS] = useState()
    const [rp_s, setRPS] = useState()
    const [adr_s, setAdrS] = useState()
    const [street_s, setStreetS] = useState()
    const [zip_s, setZipS] = useState()
    const [tel_s, setTelS] = useState()
    const [mail_s, setMailS] = useState()
    
      

     const belling = {
        firstname: firstname,
        lastName : lastName,
        rp: rp,
        adr:adr,
        zip:zip,
        tel:tel,
        mail:mail
    }

    const bellingTo = {
        firstname: firstname_s,
        lastName : lastName_s,
        rp: rp_s,
        adr:adr_s,
        zip:zip_s,
        tel:tel_s,
    }
    
    console.log(checked)
    

    const getClient = () =>{
            localStorage.setItem('client', JSON.stringify(belling))
            localStorage.setItem('toClient', JSON.stringify(bellingTo))
    }
    //localStorage.setItem("billing", JSON.stringify(belling))
    const [cookies, setCookie] = useCookies(['user']);
    //let user =  cookies.user
   
    //console.log('user :'+typeof(cookies.user))
    useEffect(()=>{
            
                if(cookies.user === "null"){
                                console.log("User null")
                                console.log(cookies.user)
                }else{
                        console.log("user object")
                        console.log(cookies.user)
                        setFirstName(cookies.user[0].firstname)
                        setName(cookies.user[0].lastname)
                        setRP(cookies.user[0].rp)
                        setZip(cookies.user[0].zip)
                        setStreet(cookies.user[0].ville)
                        setMail(cookies.user[0].mail)
                        setTel(cookies.user[0].mob)
                }
              
    })

    return (
        <div className="bg-gray-100 lg:px-2 lg:py-4 xs:w-full lg:w-1/2">
            <div  className="w-full h-auto py-2 border-b border-gray-100">
                <span className="lg:text-lg xs:text-sm font-semibold border-b-2 border-yellow-400 capitalize py-2 pr-10 leading-tight">
                    Détails de la facturation 
                </span>
            </div>
            

            <div className="flex lg:flex-row xs:flex-col w-full h-auto py-4">

                    <div className="flex flex-col space-y-2 lg:w-1/2 xs:w-full px-4">
                        <span className="text-sm font-semibold pl-2 capitalize">nom</span>
                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400" 
                                value={firstname} 
                                onChange={(e)=>setFirstName(e.target.value)}
                        ></input>
                    </div>
                    <div className="flex xs:flex-col lg:flex-col space-y-2 xs:w-full lg:w-1/2 px-4">
                        <span className="text-sm font-semibold pl-2 capitalize">prénom </span>
                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                value={lastName} 
                                onChange={(e)=>setName(e.target.value)}
                                ></input>
                    </div>
                </div>

                <div className="flex flex-col space-y-2 w-full px-4">
                        <span className="text-sm font-semibold pl-2 capitalize">Pays / Région </span>
                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                value={rp} 
                                onChange={(e)=>setRP(e.target.value)}
                        ></input>
                </div>

                <div className="flex flex-col space-y-2 w-full px-4">
                        <span className="text-sm font-semibold pl-2 capitalize">adresse de rue </span>
                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                value={adr} 
                                onChange={(e)=>setAdr(e.target.value)}
                        ></input>
                </div>

                <div className="flex flex-col space-y-2 w-full px-4">
                        <span className="text-sm font-semibold pl-2 capitalize">Ville  </span>
                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                value={street} 
                                onChange={(e)=>setStreet(e.target.value)}
                        ></input>
                </div>

                
                <div className="flex flex-col space-y-2 w-full px-4">
                        <span className="text-sm font-semibold pl-2 capitalize">Code postal / ZIP   </span>
                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                value={zip} 
                                onChange={(e)=>setZip(e.target.value)}
                        ></input>
                </div>

                <div className="flex flex-col space-y-2 w-full px-4">
                        <span className="text-sm font-semibold pl-2 capitalize">numéro de téléphone  </span>
                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                value={tel} 
                                onChange={(e)=>setTel(e.target.value)}
                        ></input>
                </div>

                <div className="flex flex-col space-y-2 w-full px-4">
                        <span className="text-sm font-semibold pl-2 capitalize">adresse e-mail   </span>
                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                value={mail} 
                                onChange={(e)=>setMail(e.target.value)}
                        ></input>
                </div>


                <div className="w-full h-10 bg-yellow-200 flex flex-row items-center px-4 mt-2">
                        <span className="text-sm text-gray-600 mr-6">Expédier à une adresse différente? </span>
                        <input 
                                type="checkbox"
                                //onClick={()=>{setSa(true)}}
                                checked={checked} 
                                onChange={handleChange}
                                className="w-4 h-4"/>
                </div>      
               
                       
                 <div className="w-full h-auto" 
                 style={{
                         //if(checked.toString === true){}else{}
                         display: checked === false ? "none" : "flex"
                         }}>
                                 <div className="w-full flex flex-col bg-red-100 py-2">
                                        <div className="flex lg:flex-row xs:flex-col w-full h-auto py-4">

                                                <div className="flex flex-col space-y-2 lg:w-1/2 xs:w-full px-4">
                                                <span className="text-sm font-semibold pl-2 capitalize">nom</span>
                                                <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400" 
                                                        value={firstname_s} 
                                                        onChange={(e)=>setFirstNameS(e.target.value)}
                                                ></input>
                                                </div>
                                                <div className="flex xs:flex-col lg:flex-col space-y-2 xs:w-full lg:w-1/2 px-4">
                                                <span className="text-sm font-semibold pl-2 capitalize">prénom </span>
                                                <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                                        value={lastName_s} 
                                                        onChange={(e)=>setNameS(e.target.value)}
                                                        ></input>
                                                </div>
                                                </div>

                                                <div className="flex flex-col space-y-2 w-full px-4">
                                                        <span className="text-sm font-semibold pl-2 capitalize">Pays / Région </span>
                                                        <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                                                value={rp_s} 
                                                                onChange={(e)=>setRPS(e.target.value)}
                                                        ></input>
                                                </div>


                                                <div className="flex flex-col space-y-2 w-full px-4">
                                                <span className="text-sm font-semibold pl-2 capitalize">adresse de rue </span>
                                                <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                                        value={adr_s} 
                                                        onChange={(e)=>setAdrS(e.target.value)}
                                                ></input>
                                                </div>

                                                <div className="flex flex-col space-y-2 w-full px-4">
                                                <span className="text-sm font-semibold pl-2 capitalize">Ville  </span>
                                                <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                                        value={street_s} 
                                                        onChange={(e)=>setStreetS(e.target.value)}
                                                ></input>
                                                </div>


                                                <div className="flex flex-col space-y-2 w-full px-4">
                                                <span className="text-sm font-semibold pl-2 capitalize">Code postal / ZIP   </span>
                                                <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                                        value={zip_s} 
                                                        onChange={(e)=>setZipS(e.target.value)}
                                                ></input>
                                                </div>

                                                <div className="flex flex-col space-y-2 w-full px-4">
                                                <span className="text-sm font-semibold pl-2 capitalize">numéro de téléphone  </span>
                                                <input type="text" className="w-full h-12 rounded-full border pl-8 focus:border-yellow-400"
                                                        value={tel_s} 
                                                        onChange={(e)=>setTelS(e.target.value)}
                                                ></input>
                                                </div>
                                 </div>
                         </div>    
                                
                                        
             
                      


                 <div className="w-full h-auto flex flex-row justify-end mt-2">
                  <Link onClick={()=>getClient()}  to={`payment/`} className="lg:text-md xs:text-sm lg:font-bold xs:font-medium bg-yellow-500 text-black hover:bg-black  mt-2 hover:text-white px-4 py-2 capitalize rounded-full  ml-4 text-center">Valider</Link> 
                </div>       
                
        </div>
    )
}

export default MainDetails
