import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Card from './Card';
import { useCookies } from 'react-cookie';
import SpecialOffer from './SpecialOffer';

//import imag from '../images/1.png';


function AllProd() {
  const [products, setProducts] = useState([])
  const [load, setLoad] = useState(false)
  const urlAPI = "https://apis.gomispro.com/product/all"
  
  const [cookies, setCookie] = useCookies(['user']);
  //setCookie('user', "null", { path: '/' });

  useEffect(() => {
    axios.get(urlAPI).then(res => { 
      setProducts(res.data)
      setLoad(true)
      
    }).catch(err => {
      console.log(err)
    })
  }, [])

  //console.log(products)

  return (
    <div className="h-auto lg:w-full xs:w-full flex flex-col">
        {/**  product */}
        <div className="w-auto h-auto  overflow-hidden  py-2 lg:px-4 xs:px-1">
            <Card products={products} />
          
          
        </div>
        
    </div>
    
  )
}

export default AllProd
/*
<li key={index} className="text-sm text-red-500">
           {product.produit}
           </li>
           */