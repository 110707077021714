import React, { useEffect, useState } from 'react'
const Facture = ()=> {
    const cartItems = JSON.parse(localStorage.getItem("cartitems"))
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems(cartItems)
    }, [])
    return (
   
            <div className="w-full h-screen flex flex-col pl-12 pr-12">
                <div className="w-full h-72 bg-yellow-300 px-8 py-4 justify-center flex mb-4">
                    <span className="text-xl font-bold text-black">
                        Merci. votre commande a été reçue
                    </span>
                </div>


                <div className="w-full h-auto flex flex-col border border-gray-600">
                    <div className="w-full h-auto py-2 flex flex-row justify-center">
                        <span className="text-md font-bold uppercase text-black">FACTURE</span>
                    </div>
                    <div className="w-full h-auto flex flex-row mb-1">
                        <div className="w-1/2 h-auto flex flex-col px-4">
                            <div>FRESH CAR 99</div>
                            <div><span>contact@freshcar99.com</span></div>
                            <div><span>06 00 00 00 00</span></div>
                            <div><span>autre information</span></div>
                        </div>
                        <div className="w-1/2 h-auto flex flex-col px-4 border-l">
                            <div><span>Nom & prenom client</span></div>
                            <div><span>Adress client ville pay</span></div>
                            <div><span>numero mobile </span></div>
                            <div><span>email client</span></div>
                        </div>
                    </div>
                    <div className="w-full h-auto px-4 py-4 border-t">
                            {
                                Object.keys(items).map((key, index)=>
                                <div className="w-full h-auto flex flex-row items-center py-2 px-4 bg-gray-100 mb-1">
                                        <span className="text-sm w-3/6">{items[key].itembrand} - {items[key].itemname}</span>
                                        <span className="text-sm w-1/6">{items[key].itemprice} </span>
                                        <span className="text-sm w-1/6">{items[key].itemqtt} </span>
                                        <span className="text-sm w-1/6 ">{ parseFloat(items[key].itemprice) * parseFloat(items[key].itemqtt) } </span>
                                    </div>
                                )
                               
                            }
                    </div>
                    <div className="w-1/3 h-auto py-4 px-4 border m-2 bg-gray-100">
                        <div className="w-full h-auto flex flex-row py-1 px-2"><span className="text-sm capitalize text-bold">sous total : </span><span></span></div>
                        <div className="w-full h-auto flex flex-row py-1 px-2"><span className="text-sm capitalize text-bold">Transport : </span><span></span></div>
                        <div className="w-full h-auto flex flex-row bg-yellow-300 py-1 px-2"><span className="text-sm capitalize text-bold">total payée : </span><span></span></div>
                    </div>
                </div>
            </div>
            
       
    )
}

export default Facture
