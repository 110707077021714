import React, { useEffect, useState } from 'react'
import axios from 'axios';

const Navl3 = () => {
    const [categorie, setCategorie] = useState([])
    const urlAPI = "https://apis.gomispro.com/categorie/categories.php"

    useEffect(()=>{
        axios.get(urlAPI).then(res => { 
            setCategorie(res.data)
            
          }).catch(err => {
            console.log(err)
          })
    }, [])

    console.log(categorie)

    return (
        <div className="flex flex-row h-auto w-full bg-grey-50 lg:px-12 lg:mb-6 xs:hidden">
            
            <ul className="w-full flex flex-row flex-wrap py-2">
                {
                    categorie.map((c, index)=>
                        <li className="w-auto h-auto py px-2 mx-2 cursor-pointer rounded-lg hover:bg-yellow-400 hover:shadow-md   hover:text-white">
                            <span className="text-xs">
                                {c.cat}
                            </span>
                        </li>
                        
                    )
                }
            </ul>
            
        </div>
    )
}

export default Navl3