import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CartNumber from './cartNumber';
import { useCookies, withCookies, Cookies } from 'react-cookie';
const Navl2 = () => {
    const [cookies, setCookie] = useCookies(['user_name']);
    //const { count } = useSelector(state => state.cartCounter)
    const count = useSelector((state)=> state.cartCounter.count)
    const amount = useSelector((state)=> state.totalInCart.total)
    //const dispatch = useDispatch()
    return (
        <div className="w-full h-auto lg:px-12 lg:py-4 xs:px-2 xs:py-2 flex flex-row justify-between lg:bg-gray-50 xs:bg-yellow-400 z-10">
            <div className="w-12 h-12 py-2">
                <img className="h-10 w-10" src={require('../images/logo/logo.png').default}/>
            </div>
            <div className="lg:w-4/6 lg:px-8 xs:hidden">
                <div className="border-2 w-3/4 h-auto border-yellow-400 rounded-full overflow-hidden flex flex-row flex-grow">
                    <input type="text" className="w-full" />
                    <button className="bg-yellow-400 w-12 h-12 text-center px-4">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 font-bold" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </button>
                </div>
            </div>

            <div className="lg:w-auto lg:h-12">
                <ul className="w-auto h-12 space-x-6 flex flex-row justify-between items-center">
                    <li className="w-6 h-6 text-center align-middle">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                    </li>
                    <Link to={ cookies.state === "true" ? "/profile" : "/account"
                            
                        }>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                    </Link>
                    <Link to={`/cart`} className="">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                        </svg>
                        <div className="w-6 h-6 rounded-full bg-yellow-500 flex justify-center items-center absolute -mt-2 -ml-2 ">
                            
                                <span className="text-sm font-bold">
                                    {count}
                                </span>
                           
                            
                        </div>
                        <span className="text-sm font-bold xs:hidden lg:inline-block">{parseFloat(amount).toFixed(2)}.DA</span>
                    </Link>
                    <li className="w-6 h-6 text-center align-middle">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navl2
