import React, {useEffect, useState} from 'react'
import { useCookies, withCookies, Cookies } from 'react-cookie';
import { Link, useHistory} from 'react-router-dom';
import axios from 'axios';

const MyOrders = () =>{

         const history = useHistory()
         const [order, setOrder] = useState([])
         const [cookies, setCookie] = useCookies(['user']);
         let user =  cookies.user
         let ufn = user[0].firstname
         let uln   = user[0].lastname 
         const urlAPI = `https://apis.gomispro.com/orders/myorders.php?fn=${ufn}&ln=${uln}`

         
          useEffect(() => {
            axios.get(urlAPI).then(res => { 
                setOrder(res.data)
            }).catch(err => {
                console.log(err)
                })
            }, [])
        
            //let x = JSON.parse(order)
            
            //console.log(order[0].ido)
    return (
        <div className="w-full h-auto lg:px-12 xs:px-2 flex flex-col">
            <div className="w-full h-auto py-2 px-4 flex flex-col ">
            <span className="text-md text-gray-900 capitalize">Mes commandes </span>
                <span className="text-sm text-gray-700">vous pouvez consulter toutes vos commandes ici </span>
            </div>

            {/** list of  */}
            <div className="w-full h-auto flex flex-col lg:mt-8 xs:mt-4">
                        <div className="w-full h-12 flex flex-row">
                                <div className="w-1/5 xs:px-2 lg:px-4 py-2 xs:text-xs lg:text-sm font-semibold capitalize">n° de facture </div>
                                <div className="w-1/5 xs:px-2 lg:px-4 py-2 xs:text-xs lg:text-sm font-semibold capitalize">date</div>
                                <div className="w-1/5 xs:px-2 lg:px-4 py-2 xs:text-xs lg:text-sm font-semibold capitalize">total</div>
                                <div className="w-1/5 xs:px-2 lg:px-4 py-2 xs:text-xs lg:text-sm font-semibold capitalize">transport</div>
                                <div className="w-1/5 xs:px-2 lg:px-4 py-2 xs:text-xs lg:text-sm font-semibold capitalize">facture</div>
                            </div>
                {
                       Object.keys(order).map((key, index)=>
                            <div className="w-full h-auto flex flex-row border-b border-gray-200 py-2 items-center">
                                <div  className="w-1/5 xs:px-2 lg:px-4 truncate py-2 xs:text-xs lg:text-sm font-semibold ">{order[key].ido}</div>
                                <div  className="w-1/5 xs:px-2 lg:px-4 truncate py-2 xs:text-xs lg:text-sm font-semibold ">{order[key].date}</div>
                                <div  className="w-1/5 xs:px-2 lg:px-4 truncate py-2 xs:text-xs lg:text-sm font-semibold ">{order[key].total}</div>
                                <div  className="w-1/5 xs:px-2 lg:px-4 truncate py-2 xs:text-xs lg:text-sm font-semibold ">{order[key].shipping}</div>
                                <div  className="w-1/5 xs:px-2 lg:px-4 truncate py-2 xs:text-xs lg:text-sm font-semibold ">
                                    <button className="w-auto h-auto p-2 hover:bg-yellow-400 group rounded-md shadow-md">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-500 group-hover:text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </button>
                                </div>

                            </div>
                   )
                }
                 
            </div>
        </div>
    )
}

export default MyOrders
