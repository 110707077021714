import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom';

const images = [
    {
        logo:"carpro.jpg",
        brand:"carpro"
    },
    {
        logo:"chemical guys.jpg",
        brand:"chemical guys"
    },
    {
        logo:"Koch-Chemie.jpg",
        brand:"Koch-Chemie"
    },
    {
        logo:"lake country.jpg",
        brand:"lake country"
    },
    {
        logo:"liquid elements.jpg",
        brand:"liquid elements"
    },
    {
        logo:"madness.jpg",
        brand:"madness"
    },
    {
        logo:"rupes.jpg",
        brand:"rupes"
    },
    {
        logo:"soft99.jpg",
        brand:"soft99"
    }
    //"carpro.jpg", "chemical guys.jpg", "Koch-Chemie.jpg", "lake country.jpg", "liquid elements.jpg", "madness.jpg", "rupes.jpg", "soft99.jpg", "walfgang.jpg" 
]


const Brands = () =>{
    let i = images.length
    /*
    const swiper = new Swiper('.swiper-container', {
        slidesPerView: 4,
        speed: 400,
        spaceBetween: 10,
        
        autoplay: {
            delay: 1000,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
    })
    */
    
    
    const settings = {
        infinite:true,
        //autoplaySpeed: 2000,
        slidesToShow:6,
        slidesToScroll: 1,
        autoplay: true,
        cssEase: "linear",
        swipeToSlide: true,
        responsive: [
            {
              breakpoint: 320,
              settings: {
                //speed: 100,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite:true
              }
            }
          ]
    }
    

    return (
        <div className="w-full h-auto flex flex-col justify-center lg:px-12 lg:mb-12 xs:px-4 bg-gray-800">
                {/*
                <div className="w-full h-auto pl-2 mb-2">
                    <span className="text-md font-semibold py-2 border-b-2 border-yellow-400 pr-2">NOS MARQUES</span> 
                </div>
                */
                <Slider {...settings} className="w-auto h-auto py-2 ">
                    {
                        images.map((image, index)=>(
                            <Link to={`brand/${image.brand}`}  className="mr-2" >
                                <div className="flex flex-row border px-1 py-1  border-gray-100 rounded-full  items-center hover:border-yellow-300 hover:bg-yellow-400">
                                    <img className="lg:w-10 lg:h-10 rounded-full lg:mr-4 xs:w-4 xs:h-4" src={require(`../images/brands_logo/${image.logo}`).default} />
                                    <span className="truncate text-sm font-bold text-white capitalize">{image.brand}</span>
                                </div>
                                
                            </Link>
                        ))
                    }
                </Slider>
                }
              
       </div>
    )
}

export default Brands
