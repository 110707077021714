import React, {Fragment, useState, useEffect } from 'react';
import {helmet } from 'react-helmet';
import {Link, redirect } from "react-router-dom";
import AllProd from '../components/AllProd';
import Nav from '../components/Nav';
import Brands from '../components/Brands';
import BestProduct from '../components/BestProduct';
import SpecialOffer from '../components/SpecialOffer'
import BigBanner from '../components/BigBanner';
import SlideShow from '../components/SlideShow';
const axios = require('axios');


export default function Home() {
    /*
    const [prods, setProd] = useState({});

    const apiURL = "http://localhost:5000/all";
    useEffect(() => {
        axios.get(apiURL).then(res => {
            setProd(res.data)
        }).catch(err => {
            console.log(err)
        }) 
        //return () => {
          //  cleanup
        //}
    }, [])
    */
    return(
        <div className="">
            <Nav />
            {/* 
            <div className="h-screen w-full flex flex-row lg:px-12 lg:py-4 mb-8 mt-20">
                    <SpecialOffer  />
                    <SlideShow />
            </div>
            */}
            <div className="w-full h-96  mt-20 overflow-hidden bg-no-repeat bg-left-bottom bg-cover" style={{backgroundImage:`url(${require("../images/banner/background.jpg").default})`}}>
                <div className="w-full h-full flex flex-row justify-center px-12">
                    <div className="w-96 h-96 flex flex-col justify-center overflow-hidden">
                        <div className=" w-full h-auto">
                            <span className="text-sm text-blue-400 font-bold uppercase mb-2">
                                gyeon
                            </span>
                        </div>
                        <div>
                            <span className="text-2xl text-gray-700 uppercase mb-2 font-black">
                                cancoat
                            </span>
                        </div>
                        <div className="w-auto h-auto mb-2">
                            <span className="text-sm text-gray-600 font-medium">
                             Le Gyeon Q2 Cancoat apporte une brillance et un effet hydrophobe tout à fait unique pour un produit de ce type. Il forme une couche plus dure et plus durable
                            </span>
                        </div>
                        <div>
                            <Link to="/" className="w-auto h-auto px-8 py-2 bg-yellow-300 rounded-md shadow-sm">
                                Acheter maintenant 
                            </Link>
                        </div>
                    </div>
                    <div className="w-96 h-96 flex justify-center items-center" >
                        <div className="w-72 h-72 bg-cover" style={{backgroundImage:`url(${require("../images/9.png").default})`}}></div>
                    </div>
                </div>
            </div>
            <Brands className="mt-24"/>
            {/*
            <div className="w-full h-52 bg-gray-200 lg:px-1 lg:py-4 xs:px-4 xs:py-2 flex flex-row items-center overflow-hidden">
                <div className="w-auto h-full px-2 bg-green-300 flex flex-row">
                        <div className="w-96 h-full flex flex-row bg-red-300 rounded-md shadow-sm mx-2"></div>
                        <div className="w-96 h-full flex flex-row bg-red-300 rounded-md shadow-sm mx-2"></div>
                        <div className="w-96 h-full flex flex-row bg-red-300 rounded-md shadow-sm mx-2"></div>
                        <div className="w-96 h-full flex flex-row bg-red-300 rounded-md shadow-sm mx-2"></div>
                        <div className="w-96 h-full flex flex-row bg-red-300 rounded-md shadow-sm mx-2"></div>
                </div>
                
            </div>
            */}
            <AllProd />
        </div>
            )
}