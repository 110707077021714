import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {reactLocalStorage} from 'reactjs-localstorage';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { increment } from '../redux/cartCounter'
import { addtoamount } from '../redux/totalInCart'
import quantity, { incrementQtt, decrementQtt } from '../redux/quantity'
import SameVolume from './SameVolume';

const MainItem = (props) => {
    let cartItems = JSON.parse(localStorage.getItem("cartitems"))
    let cn = localStorage.getItem("cartnumber")
    let totalInCart = localStorage.getItem("totalincart")
    
    let idv
    const quant = useSelector((state)=> state.quantity.qtt)
    const dispatch = useDispatch();
    
    const dispatchIcrementQtt = () =>{
       
        dispatch(incrementQtt());
    }

    const dispatchdecrementQtt = () =>{
       
        dispatch(decrementQtt());
    }

    const disp = () => {
            dispatch(increment());
    }
    
    const alterAmount = (q,p) => {
            let amount = q*p 
            dispatch(addtoamount(amount));
    }
   
    const handelAddToCart = (Object, quant) =>{
       
        
       const item ={
            itemid:Object.idvolume,
            itemname:Object.produit,
            itembrand:Object.brand,
            itemprice:Object.price,
            itemvolum:Object.volume,
            itemqtt:quant
        }
        idv = item.itemid
        //reactLocalStorage.setObject('cartitems', { `${idv}` : item});
      
        if(cartItems != null){
            if(cartItems[item.itemid] == undefined){
                console.log("! in")
                //let cii =  Object.assign(cartItems, o)
                const i = item.itemid
                cartItems[i] = item
                //localStorage.setItem("cartitems", JSON.stringify(cartItems))
                
                alterAmount(item.itemqtt,item.itemprice);
                disp();
                
                totalInCart = JSON.parse(totalInCart) + (item.itemqtt * item.itemprice)   
                //alert(typeof(JSON.parse(totalInCart)))
                cn = parseInt(JSON.parse(cn)) + 1
                //localStorage.setItem('cartnumber', JSON.stringify(cn))
                //localStorage.setItem('totalincart', JSON.stringify(totalInCart))
            }else{
                //console.log(" in")
                alterAmount(item.itemqtt,item.itemprice);
                totalInCart = JSON.parse(totalInCart) + (item.itemqtt * item.itemprice)
                cartItems[item.itemid].itemqtt = parseInt(cartItems[item.itemid].itemqtt) + parseInt(item.itemqtt);
                //localStorage.setItem('cartnumber', JSON.stringify(cn))
                //localStorage.setItem('totalincart', JSON.stringify(totalInCart))
            }
            
        }else{
            
            //console.log("null")
            let o = {}
            let k = item.itemid
            let v = item
            o[k] = v
           //localStorage.setItem("cartitems", JSON.stringify(o))
           cartItems = o
           totalInCart = (item.itemqtt * item.itemprice) 
           //totalInCart = (item.itemqtt * item.itemprice) 
           cn =  1 ;
           disp();
           alterAmount(item.itemqtt,parseFloat(item.itemprice));
           //localStorage.setItem('cartnumber', JSON.stringify(cn))
           //localStorage.setItem('totalincart', JSON.stringify(totalInCart))
        }
        
        localStorage.setItem('cartitems', JSON.stringify(cartItems))
        localStorage.setItem('cartnumber', JSON.stringify(cn))
       
        localStorage.setItem('totalincart', JSON.stringify(totalInCart))
        
        
    }
    
    /*
    const refreshPage = ()=>{
        window.location.reload();
     }
     */

    const { id , brand } = useParams();
    //const [idd, setIdd] = useState(props.productid);
    const [products, setProduct] = useState([])
    
    
   
     console.log(products)

    const [qtt, setQtt] = useState()
    //console.log(this.props.location.id)
        const urlAPI = `https://apis.gomispro.com/product/product.php?idv=${id}`
        
        useEffect(() => {
            axios.get(urlAPI).then(res => { 
            setProduct(res.data)
        }).catch(err => {
            console.log(err)
            })
        }, [])
        
        let likes = Math.floor(Math.random() * 201)

    return (
        <div className="lg:w-3/4 lg:h-auto  xs:w-full xs:h-auto ">
            {products.length && products.map((product, index) =>
                             
                                        
                <div className="w-full h-auto flex flex-col divide-y-2 ">
                                       
                        <div className="w-full h-auto mb-4 py-4 flex lg:flex-row xs:flex-col ">
                            <div className="flex flex-col">
                                <div className="product-detail-image bg-contain bg-no-repeat bg-center" 
                                     style={{backgroundImage:`url(https://apis.gomispro.com/product/images/${product.thumb})`}}>
                                    {/**
                                     * style={{backgroundImage:`url(https://apis.gomispro.com/product/images/${product.thumb})`}}
                                     */}
                                    
                                </div>
                                <ul className="flex-row mt-4 flex-wrap hidden">
                                        <li className="w-24 h-24 rounded-md hover:border-yellow-400 hover:shadow-md border-2 cursor-pointer"> 
                                            <img src={require(`../images/10.png`).default}  className/>
                                        </li>
                                        <li className="w-24 h-24 rounded-md hover:border-yellow-400 hover:shadow-md border-2 cursor-pointer"> 
                                            <img src={require(`../images/8.png`).default} />
                                        </li>
                                        <li className="w-24 h-24 rounded-md hover:border-yellow-400 hover:shadow-md border-2 cursor-pointer"> 
                                            <img src={require(`../images/9.png`).default} />
                                        </li>
                                        <li className="w-24 h-24 rounded-md hover:border-yellow-400 hover:shadow-md border-2 cursor-pointer"> 
                                            <img src={require(`../images/13.png`).default} />
                                        </li>
                                        <li className="w-24 h-24 rounded-md hover:border-yellow-400 hover:shadow-md border-2 cursor-pointer"> 
                                            <img src={require(`../images/12.png`).default} />
                                        </li>
                                </ul>
                            </div>
                            
                    
                            <div className="flex flex-col flex-1  divide-y devide-gray-700 pl-4 pr-4 pb-2">
                             <div className="w-full h-auto flex flex-col pl-4">
                                <span className="text-xs capitalize pb-2" id="brand">{product.brand}</span>
                                <span className="text-2xl font-semibold" id="product">{product.produit} - {product.volume}{product.unite}</span>
                                <span className="font-medium py-4 xs:text-xs capitalize text-green-500"
                                style={{display : product.instock > 0 ? "flex" : "none"}}
                                >disponible</span>
                                <span className="font-medium py-4 xs:text-xs capitalize text-red-400"
                                style={{display : product.instock == 0 ? "flex" : "none"}}
                                >hors stock</span>
                             </div>
                             
                             <div className="flex flex-row h-auto w-full pl-4 py-2">
                                    <span className="pr-2 font-bold text-green-400">{likes}</span>
                                    <span className="capitalize pr-6 xs:text-xs">personne aimes ce produit</span>
                                    <button classsName="">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-400" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                    </svg>
                                    </button>
                             </div>

                             <div className="lg:py-4 lg:px-4 xs:px-0 h-auto w-full">
                                 <div classsName="w-full h-auto pb-4">
                                    <ul className="pl-4 hidden">
                                        <li className="text-sm">Pair and Play with your Bluetooth® device with 30' range</li>
                                        <li className="text-sm">Pair and Play with your Bluetooth® device </li>
                                        <li className="text-sm">Pair and Play with your Bluetooth® device with 30' </li>
                                        <li className="text-sm">Pair and Play with your Bluetooth® </li>
                                    </ul>
                                 </div>

                                 <div className="h-auto w-full pt-8 pb-8">
                                    <span className="text-3xl font-semibold flex flex-row" id="price">
                                        {product.price}
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>
                                 </div>
                                <div className="h-auto w-full pt-8 pb-8">
                                    <SameVolume productP={product.produit} productB={product.brand}/>
                                 </div> 
                                 <div classsName="w-full h-auto flex flex-col lg:px-4 lg:py-4 xs:px-0">
                                     <div className="flex flex-row mb-4">
                                         <span className="w-10 h-10 bg-gray-50 border border-gray-100 px-2 py-2 hover:bg-yellow-400" onClick={
                                             () => dispatchdecrementQtt()
                                         } >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6" />
                                            </svg>
                                         </span>

                                         <input type="text" required disabled value={quant} className=" border border-gray-100  bg-gray-50 w-24 h-10 px-4 py-2 text-center" onChange={
                                                (e)=>{
                                                    setQtt(e.target.value);
                                                }
                                            }/>

                                         <span className="w-10 h-10 bg-gray-50 border border-gray-100 px-2 py-2 hover:bg-yellow-400" onClick={
                                            () => dispatchIcrementQtt()
                                         }>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                         </span>
                                     </div>

                                    
                                        
                                    <button onClick={() =>  
                                            
                                        handelAddToCart(product, quant)  
                                    
                                    } 
                                        disabled = {product.instock == 0 ? true : false}
                                        className= {product.instock > 0 ? "bg-yellow-400 px-6 py-2 w-auto text-white font-semibold hover:bg-black rounded-full" : "px-6 py-2 w-auto hover:text-white font-semibold hover:bg-black rounded-full border border-black"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-6 w-4 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                        Ajouter au panier </button>
                                 </div>
                             </div>

                             
                       </div>
                       

                        </div>

                        <div className="w-full h-auto py-4 px-4">
                            <div>
                                <span className="text-2xl font-bold text-black">
                                    à propos de ce produit 
                                </span>
                            </div>
                            <div className="w-full px-4">
                                <span className="xs:text-xs lg:text-sm">
                                    {product.descript}
                                </span>
                                
                            </div>
                            
                        </div>
                </div>
                
            )}
        </div>
    )
}

export default React.memo(MainItem)
