import React, {useEffect, useState} from 'react'
import { useCookies, withCookies, Cookies } from 'react-cookie';
import { Link, useHistory} from 'react-router-dom';
import axios from 'axios';

const UserDtails = () =>{
        
        const history = useHistory()

         const [cookies, setCookie] = useCookies(['user']);
         let user =  cookies.user

        const [firstname, setFirstName] = useState()
        const [lastName, setName] = useState()
        const [rp, setRP] = useState()
        const [adr, setAdr] = useState()
        const [street, setStreet] = useState()
        const [zip, setZip] = useState()
        const [tel, setTel] = useState()
        const [mail, setMail] = useState()

        let newUser =  {
            idc : user[0].idc,
            firstName: firstname,
            lastName: lastName,
            rp: rp,
            adr: adr,
            zip: zip,
            tel: tel,
            mail: mail,
            ville: street
          }
          // to send 
        const urlAPI = `https://apis.gomispro.com/user/updateUser.php?client=${JSON.stringify(newUser)}`
        const updateUser = () =>{
            axios.get(urlAPI).then((res) => {
                history.push('/profile')
              });
        }
    return (
        <div className="w-full  h-auto min-h-screen lg:pt-20  xs:pt-12 flex flex-col items-center">
                <div className="w-full h-auto bg-black flex flex-row lg:px-12 xs:px-2 py-2 justify-between items-center">
                            <span className="lg:text-sm xs:text-xs font-bold capitalize text-gray-100">
                                    annuler modifier les détails de votre compte 
                            </span>
                            <Link to={'/profile'} classsName="w-12 h-auto  ">
                                <span className="lg:text-sm xs:text-xs text-black font-bold px-4 py-2  bg-yellow-400  rounded-full">Annuler</span>
                            </Link>
                    </div>
                <div className="lg:w-1/2 xs:lg-full h-auto l:px-4 xs:px-2 py-2">
                    <span className="text-sm ">
                     À partir du tableau de bord de votre compte, vous pouvez consulter vos commandes récentes, gérer vos adresses de livraison et de facturation et modifier votre mot de passe et les détails de votre compte. 
                    </span>
                </div>
                <div className="lg:w-1/2 xs:w-full h-auto  px-4 py-4 ">    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Nom </span>
                            <input  type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            placeholder={user[0].uname}
                            value={firstname} 
                            onChange={(e)=>setFirstName(e.target.value)}></input>
                        </div>
    
                    </div>
    
                    <div className="w-full h-auto flex flex-col w-full">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">prénom   </span>
                            <input  type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            //placeholder={}
                            value={lastName} 
                            onChange={(e)=>setName(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Pays / Région   </span>
                            <input  type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={rp} 
                            onChange={(e)=>setRP(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">adresse de rue   </span>
                            <input type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            placeholder={user[0].adr}
                            value={adr} 
                            onChange={(e)=>setAdr(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Ville   </span>
                            <input  type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={street} 
                            onChange={(e)=>setStreet(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Code postal / ZIP  </span>
                            <input  type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            value={zip} 
                            onChange={(e)=>setZip(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">num tel  </span>
                            <input  type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            placeholder={user[0].mob}
                            value={tel} 
                            onChange={(e)=>setTel(e.target.value)}></input>
                        </div>
                    </div>
    
                    <div className="w-full h-auto flex flex-col ">
                        <div className="flex flex-col space-y-2">
                            <span className="text-sm font-semibold pl-2 capitalize">e-mail </span>
                            <input  type="text" className="w-full h-12 rounded-full border pl-8 font-bold text-blue-600 bg-gray-100"
                            placeholder={user[0].mail}
                            value={mail} 
                            onChange={(e)=>setMail(e.target.value)}></input>
                        </div>
                    </div>
                </div>
                <div className="w-full h-auto py-2 px-12 bg-red-300 flex flex-row justify-end">
                    <button className="w-auto h-auto px-4 py-2 text-md font-bold bg-black text-white capitalize hover:bg-yellow-400 hover:text-black rounded-full"
                            onClick={()=>updateUser()}>modifier</button>
                </div>
            </div>
    )
}

export default UserDtails
