import Home from "../pages/home";
import Product from "../pages/product"
import Cart from "../pages/cart"
import Account from "../pages/account"
import Checkout from "../pages/checkout";
import PayPal from "../pages/PayPal";
import { Route, Switch, BrowserRouter as Router} from "react-router-dom";
import Facturation from "../pages/Facturation";
import Success from "../pages/success";
import Brand from "../pages/brand";
import Payment from "../pages/payment";
import Profile from "../pages/Profile";
import Dashboard from "../pages/user/dashboard";
import Securite from "../pages/user/securite";
import UserOrders from "../pages/user/userorders";
import userDetails from "../pages/user/userdetails";

// ADMIN
import adminLog from "../pages/admin/adminlog";
import Items from "../pages/admin/items";
import AddItem from "../pages/admin/additem";
import UpdateItem from "../pages/admin/updateItem";
//import Login from "../pages/Login";

/*

const routes = [
    {path: "/", name: "home", component: Home},
    {path: "/product/:id", name: "product", component: Product},
    {path: "/account", name: "account", component: Account},
    {path: "/checkout/", name: "checkout", component: Checkout},
    {path: "/cart/", name: "cart", component: Cart},
    {path: "/PayPal", name: "paypal", component: PayPal},
    //{path: "/login", name: "login", component: Login},
];

*/
const Routes = () =>{
    return(
        <Router>
            <Switch>
                <Route exact path="/" name="home" component={Home} />
                <Route exact path="/product/:id" name="product" component={Product} />
                <Route exact path="/account" name="account" component={Account} />
                <Route exact path="/checkout" name="checkout" component={Checkout} />
                <Route exact path="/cart" name="cart" component={Cart} />
                <Route exact path="/paypal" name="paypal" component={PayPal} />
                <Route exact path="/facturation" name="facturation" component={Facturation} />
                <Route exact path="/success" name="Success" component={Success} />
                <Route exact path="/brand/:br" name="Brand" component={Brand} />
                <Route exact path="/payment" name="Payment" component={Payment} />
                <Route exact path="/profile" name="Profile" component={Profile} />
                <Route exact path="/dashboard" name="dashboard" component={Dashboard} />
                <Route exact path="/securite" name="securite" component={Securite} />
                <Route exact path="/orders" name="orders" component={UserOrders} />
                <Route exact path="/details" name="userdetails" component={userDetails} />
                <Route exact path="/admin" name="adminlog" component={adminLog} />
                <Route exact path="/items" name="items" component={Items} />
                <Route exact path="/additem" name="additem" component={AddItem} />
                <Route exact path="/updateitem/:id" name="updateitem" component={UpdateItem} />
            </Switch>
        </Router>
    )
}


export default Routes;