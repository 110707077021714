import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import axios from "axios"
const AlterItem = ( props ) =>{

    const { id  } = useParams();
    const urlAPI = `https://apis.gomispro.com/product/product.php?idv=${id}`

    const [products, setProduct] = useState([])

    useEffect(() => {
        axios.get(urlAPI).then(res => { 
        setProduct(res.data)
    }).catch(err => {
        console.log(err)
        })
    }, [])

    //const [cat, setCat] = useState([])
    const urlAPICAT = "https://apis.gomispro.com/categorie/categories"
    useEffect(() => {
        axios.get(urlAPICAT).then(res => { 
        setCategorie(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])

    const urlAPIBRAND = "https://apis.gomispro.com/brand/allbrand"
    useEffect(() => {
        axios.get(urlAPIBRAND).then(res => { 
        setBrand(res.data)
        //setLoad(true)
        
        }).catch(err => {
        console.log(err)
        })
    }, [])

    let  ph_np, ph_brand, ph_price, ph_ref, ph_qtt, ph_volume, ph_unite, ph_categorie, ph_descrip
    console.log(products)
    products.length && products.map((p,index) =>
        {
              ph_np = p.produit
              ph_brand = p.brand
              ph_price = p.price
              ph_ref = p.red
              ph_qtt = p.instock
              ph_volume = p.volume
              ph_unite = p.unite
              ph_descrip = p.descript
              ph_categorie = p.categorie
        }
    )
    
   // console.log(products[0]['brand'])
    
   const [np, setNp] = useState("")
   const [brand, setBrand] = useState([])
   const [ref, setRef] = useState("")
   const [price, setPrice] = useState("")
   const [remise, setRemise] = useState("")
   const [qtt, setQtt] = useState("")
   const [volume, setVolume] = useState("")
   const [unite, setUnite] = useState("")
   const [categorie, setCategorie] = useState([])
   const [description, setDescription] = useState("")
   const [img, setImg] = useState([])
   
   const [cat, setCat] = useState("")
   const [brd, setBrd] = useState("")
    

   const update = (id) =>{
    console.log(" id :"+ id)
    console.log(" ref :" + ref)
   }
    return (
        <div className="w-full h-screen bg-gray-100 flex flex-col">
            
            <div className="w-full h-auto flex flex-row bg-white border-b border-gray-700 px-12 py-4">
                <div className="flex flex-row w-full h-auto"> 
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="text-md font-bold capitalize">Modifier l'article :  </span>
                    
                </div>
            </div>
            {/** ROW 1 */}
            <div className="w-full h-auto flex flex-col bg-white px-12 py-2">
                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">nom de produit :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                                value={np == "" ? ph_np : np}
                                
                                onChange={(e)=>setNp(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">marque : <span className="text-xs text-red-400">{ph_brand}</span></span>
                            <select type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                             value={brd}
                             onChange={(e)=>setBrd(e.target.value)}
                            >
                                {
                                    brand.length &&  brand.map((b, index) =>
                                        <option >{b.brand}</option>        
                                    )
                                }
                            </select>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 group-focus:text-red-400 capitalize">ref / code :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400 group"
                            value={ref == "" ? ph_ref : ref}
                            onChange={(e)=>setRef(ph_ref)}
                            ></input>
                        </div>
                </div>
                {/** ROW 2 */}
                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">prix :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            value={price == "" ? ph_price : price}
                           
                            onChange={(e)=>setPrice(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">remise :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            disabled
                            value={remise}
                            onChange={(e)=>setRemise(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 group-focus:text-red-400 capitalize">qtt en stock :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400 group"
                            value={qtt == "" ? ph_price : price}
                            placeholder={ph_qtt}
                            onChange={(e)=>setQtt(e.target.value)}
                            ></input>
                        </div>
                </div>
                {/** ROW 4 */}
                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">Volume :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            value={volume == "" ? ph_volume : volume}
                            placeholder={ph_volume}
                            onChange={(e)=>setVolume(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">unite :</span>
                            <input type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                            value={unite == "" ? ph_unite : unite}
                            placeholder={ph_unite}
                            onChange={(e)=>setUnite(e.target.value)}
                            ></input>
                        </div>
                        <div className="w-1/3 flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 group-focus:text-red-400 capitalize">categorie : <span className="text-xs text-red-400">{ph_categorie}</span></span>
                            <select type="text" className="w-full h-10  border pl-8 focus:border-yellow-400"
                             value={cat}
                             onChange={(e)=>setCat(e.target.value)}
                            >
                                {
                                    categorie.length &&  categorie.map((cat, index) =>
                                        
                                        <option >{cat.cat}</option>        
                                    )
                                }
                                
                            </select>
                        </div>
                </div>

                {/** ROW 3 */}

                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-full flex flex-col space-y-2 px-2">
                            
                            <span className="text-sm font-semibold pl-2 capitalize">images :</span>
                             {/*action={urlAPINEWITEM}*/} 
                            <form method="post" encType="multipart/form-data" id="mform" target="votar">
                                <input type="file" 
                                    name="image[]"
                                    className="w-full h-10 py-2 border pl-8 focus:border-yellow-400"
                                    multiple
                                    //value={img.length}
                                    //onChange={(e)=>setIm(e)}
                                ></input>
                       


                            </form>
                            
                        </div>
                        
                </div>
                {/** ROW 4 */}
                <div className="w-full h-auto py-2 flex flex-row justify-between">
                        <div className="w-full flex flex-col space-y-2 px-2">
                            <span className="text-sm font-semibold pl-2 capitalize">description :</span>
                            <textarea class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" rows="4"
                            value={description == "" ? ph_descrip : description}
                            placeholder={ph_descrip}
                            onChange={(e)=>setDescription(e.target.value)}
                           ></textarea>
                        </div>
                        
                </div>
                                {/** BUTTON */}
                      <div className="w-full h-auto flex flex-row justify-end px-12 py-4">
                            {/** onClick={()=>additem()} */}
                            <button type="submit" className="w-auto h-auto px-4 py-2 rounded-full bg-yellow-400 text-gray-700 capitalize text-sm font-bold"
                            onClick={()=>update(id)}>modifier</button>
                        </div>
                        <iframe name="votar" className="none"></iframe>
            </div>
            
        </div>
    )
}

export default AlterItem
