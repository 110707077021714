import React, { Component } from 'react'
import Nav from '../components/Nav'
import Billing from '../components/Billing'
import Order from '../components/Order'
import Register from '../components/Register'
//import Billing from '../components/Billing'
import { useState } from 'react'
class checkout extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             parentName : "parent"
        }

       // this.handleBilling  = this.handleBilling.bind(this)
        
    }
    getBilling(name){
        console.log(name)
    }

    handleBilling(islamo){
        //console.log(billing)
        
    }
    handelAddOrder = (b) =>{
        localStorage.setItem("billing",JSON.stringify(b))
    }


   
    render() {
        return (
            <div className="pb-32 pt-20 flex justify-center ">
                <Nav />
                {/*<Order addOrder={this.handelAddOrder} />*/}
                <Billing/>
                
            </div>
        )
    }
}

export default checkout




